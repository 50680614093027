import React, { useEffect, useState } from 'react';
import './allCreds.scss';
import { useParams } from "react-router-dom";
import logo from '../../img/EarthID Logo - Primary - BLACK.png';
import studentPhoto from '../../img/studentIcon.png';
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import QRCode from 'qrcode.react';

const moment = require('moment');

const AllCredsNew = () => {
    const [responseDataTraining, setResponseDataTraining] = useState(null);
    const [responseDataID, setResponseDataID] = useState(null);
    const [responseDataProfMem, setResponseDataProfMem] = useState(null);
    const [responseDataProject, setResponseDataProject] = useState(null);
    const [responseDataBMM, setResponseDataBMM] = useState(null);
    const [responseData, setResponseData] = useState([]);

   // console.log('This is the response data:', responseDataTraining)

const { userName, issuer } = useParams();
console.log('this is username and issuer',userName, issuer)

useEffect(() => {
    const fetchData = async () => {
      try {
      

          const userNamee = `BMMLevel1${userName}`;
          console.log('Username', userNamee);
          const cidsResponse = await fetch(`https://procreddb.myearth.id/api/vcnew/company/${userNamee}/CertificateVC`);
          const cidJson = await cidsResponse.json();
          console.log('CidsResponse', cidJson);

          if (cidJson && cidJson.response && cidJson.response.length > 0) {
            setResponseData(cidJson.response);
          } else {
            console.log('Certificate not generated');
          }
      
      } catch (error) {
        console.log('Error:', error);
      }
    };

    fetchData();
  }, [userName]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(date);
  };

    return (
        <>
            <div className="page-container">
                <nav className="navbar-cert">
                    <div className="logoAll">
                        <img src={logo} alt="EarthID Logo" />
                    </div>
                </nav>
                <div className='userNameSec'>
                    <div className='userNameSec1 container'>
                        <div className='userImg'>
                            <img className='userImg1' src={studentPhoto} alt="User" />
                        </div>
                        <div>
                            <p className='userNameall'>{userName}</p>
                            <p className='credText'>{responseData.length} Certificates | 1 Issuer</p>
                        </div>
                    </div>
                </div>
                <div className='cardSecAllBMM'>
                    <div className='cardSecAll1 row'>
                        {responseData && responseData.length > 0 && responseData.map((vc, index) => (
                            <div key={index} className="col-md-4">
                                <a href={`/v1/en/certificate/${issuer}/${userName}/${vc.version}/${vc.id}`}>
                                    <div className="blog-cardminiBMM">
                                        <div className='blogImg1BMM'>
                                            {/* You can use different images based on vc.type if needed */}
                                            {/* <img src={vc.type === "Training" ? kpTraining : kpProfMem} alt="VC Image" className="blog-card__image" /> */}
                                            <div className="certificatedminiBMM">
                                        <div className="bodboxBMM">

                                            <div className="headerBMM">
                                                <div className="favico">
                                                    <img src={favicon}></img>
                                                </div>
                                                <div className="title">BMMLEVEL1 Certificate</div>
                                            </div>
                                            <div className="title1">Year 2024-2025</div>
                                            <div className="title1">This certificate verifies that</div>

                                            <div className="body">
                                                <div className="name">{userName}</div>
                                                <div className="title1">{`${userName} achievement of a BMM Level One rating underscores its commitment to delivering high-quality blockchain solutions that meet rigorous standards. This recognition enhances ${userName}'s standing as a reliable provider in the rapidly evolving blockchain landscape`}<br></br>
                                                issued on: {formatDate(vc.createdAt)}.
                                                </div>
                                            </div>
                                            <div>
                                                {/* Add the QRCode component */}
                                                {/* <div className="qrcode-container">
                                                    <QRCode className='qrCode' value={`https://${userName}/documents/audit/${vc._id}`} />
                                                </div> */}
                                                <div className='signature'>EarthID</div>
                                                {/* <div className='signature1'>KPMG</div> */}
                                            </div>


                                        </div>
                                    </div>
                                        </div>
                                        <div className="blog-card__content">
                                            <h2 className="blog-card__title">BMMLevel1 Certificate</h2>
                                            <p className="blog-card__date">{formatDate(vc.createdAt)}</p>
                                            <p className="blog-card__issued-by">{`Issued by: ${issuer}`}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <footer className="footerrall">
                <div className="footerr-text">
                    Powered by EarthID
                </div>
            </footer>
        </>
    );
};

export default AllCredsNew;